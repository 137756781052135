<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocInutilizados.table"
      :enabledActions="false"
      :nameToExport="'Documentos Inutilizados'"
    >
      <template v-slot:column>
        <DxColumn data-field="modelo" alignment="right" />
        <DxColumn data-field="serie" alignment="right" caption="Série" />
        <DxColumn
          data-field="nIni"
          alignment="right"
          caption="Numeração Inicial"
        />
        <DxColumn
          data-field="nFim"
          alignment="right"
          caption="Numeração Final"
        />
        <DxColumn data-field="dhRecbto" data-type="date" caption="Data" />
        <DxColumn
          data-field="protocolo"
          alignment="right"
          caption="Protocolo"
        />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>