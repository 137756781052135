import { DxColumn, DxExport } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../../datagrid-factory/index.vue'
import { documentoStore } from '../../../../store/modules/documentos';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentosInutilizados',
  setup() {
    const store = documentoStore()
    
    return {
      store
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport,
  }
})
